<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the teams. Please check your connection and try
      again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadTeams"
    >
      Try Again
    </button>
  </template>

  <template v-else-if="status === 'loaded'">
    <template v-if="teams.results.length">
      <div class="teams">
        <router-link
          v-for="team of teams.results"
          :key="`team${team.slug}`"
          :to="{ name: 'team', params: { teamSlug: team.slug } }"
          class="card team"
        >
          <h2>{{ team.name }}</h2>

          <p>
            <template v-if="team.visibility === 'public'">
              Public
            </template>

            <template v-else>
              Private
            </template>

            Team
          </p>

          <p>
            Plays {{ team.game_name_and_year }}
          </p>

          <img
            v-if="team.cover_image"
            :src="team.cover_image"
            alt
            class="img-fluid"
          >
        </router-link>

        <div class="load-more-content">
          <button
            v-show="moreTeamsStatus === 'idle' && teams.next"
            ref="loadMoreTeamsButton"
            type="button"
            class="btn btn-outline-primary load-more-teams-button"
            @click="loadMoreTeams"
          >
            Load More Teams
          </button>

          <spinner
            v-if="moreTeamsStatus === 'loading'"
            preset="large"
          />

          <template v-else-if="moreTeamsStatus === 'error'">
            <alert variant="danger">
              An error occurred while trying to load more teams. Please check
              your connection and try again.
            </alert>

            <button
              type="button"
              class="btn btn-outline-primary"
              @click="loadMoreTeams"
            >
              Try Again
            </button>
          </template>
        </div>
      </div>
    </template>

    <p
      v-else
      class="text-center"
    >
      <template v-if="list === 'user'">
        You aren't on any teams yet.
      </template>

      <template v-else>
        Nothing to see here.
      </template>
    </p>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TeamInterface } from '@/interfaces/teams';

export default defineComponent({
  props: {
    list: {
      type: String,
      required: true,
      default: 'user',
      validator: (value: string) => [
        'user',
        'other',
      ].includes(value),
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    teams: {} as {
      next: string | null,
      previous: string | null,
      results: Array<TeamInterface>,
    },
    moreTeamsStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  created() {
    this.loadTeams();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadTeams() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `teams/${this.list}_teams/`,
      });

      if (responseData.status === 200) {
        this.teams = responseData.body;
        this.status = 'loaded';

        if (this.teams.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreTeams();
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreTeamsButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMoreTeams() {
      this.moreTeamsStatus = 'loading';

      const responseData = await this.api({
        url: this.teams.next as string,
      });

      if (responseData.status === 200) {
        this.teams.next = responseData.body.next;
        this.teams.previous = responseData.body.previous;
        this.teams.results = this.teams.results.concat(responseData.body.results);
        this.moreTeamsStatus = 'idle';
      } else {
        this.moreTeamsStatus = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .teams {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }

  .team {
    padding: 15px;
    color: #fff;
    text-align: center;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: var(--gray-dark);
      text-decoration: none;
    }

    h2,
    p {
      margin-bottom: 0;
    }

    img {
      display: block;
      margin-top: 1rem;
    }
  }

  .load-more-content {
    align-self: center;
  }

  .load-more-teams-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
