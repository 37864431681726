<template>
  <div class="horizontal-padding">
    <h1>Teams</h1>

    <nav
      class="navbar"
      aria-label="Team"
    >
      <ul>
        <li>
          <router-link :to="{ name: 'my_teams' }">
            My Teams
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'other_teams' }">
            Other Teams
          </router-link>
        </li>
        <li v-if="userData.subscription_status === 'active'">
          <router-link :to="{ name: 'create_team' }">
            Create a Team
          </router-link>
        </li>
      </ul>
    </nav>

    <div :class="`${activeTab}-content`">
      <team-list
        v-if="activeTab === 'my_teams'"
        list="user"
      />

      <team-list
        v-else-if="activeTab === 'other_teams'"
        list="other"
      />

      <create-team v-else-if="activeTab === 'create_team'" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CreateTeam from '@/components/teams/CreateTeam.vue';
import TeamList from '@/components/teams/TeamList.vue';

export default defineComponent({
  components: {
    CreateTeam,
    TeamList,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
  .create_team-content {
    margin: 0 auto;
    max-width: 500px;
  }
</style>
