<template>
  <div class="form-group">
    <label :for="selectedGame.slug === undefined ? 'game' : ''">Game</label>

    <div
      v-if="selectedGame.slug === undefined"
      class="input-with-prefix"
    >
      <span class="input-prefix">
        <search-icon />
      </span>
      <input
        id="game"
        v-model="searchKeywords"
        :class="{ 'is-invalid': errorMessage !== '' }"
        type="text"
        required
        placeholder="Search for a game"
        @input="searchFieldInput"
      >
    </div>

    <div
      v-else
      class="selected-game"
    >
      <img
        v-if="selectedGame.coverImageId"
        :src="`https://images.igdb.com/igdb/image/upload/t_thumb/${selectedGame.coverImageId}.jpg`"
        alt
      >

      <div>
        <div>{{ selectedGame.nameAndYear }}</div>

        <button
          type="button"
          class="btn btn-outline-primary btn-xs"
          @click="changeGame"
        >
          Change Game
        </button>
      </div>
    </div>

    <div
      v-show="selectedGame.slug === undefined && searchResultsStatus !== 'idle'"
      class="search-results-container"
    >
      <div class="search-results">
        <template v-if="searchResultsStatus === 'loaded'">
          <div
            v-if="searchResults.results.length"
            class="subtle-scrollbar search-results-list-and-load-more-content"
          >
            <ul
              class="search-results-list"
              aria-label="Search results"
            >
              <li
                v-for="result of searchResults.results"
                :key="`result${result.slug}`"
              >
                <button
                  type="button"
                  class="btn-transparent"
                  @click="$emit('changed', result)"
                >
                  <img
                    v-if="result.cover_image_id"
                    :src="`https://images.igdb.com/igdb/image/upload/t_thumb/${result.cover_image_id}.jpg`"
                    alt
                  >

                  <div>
                    {{ result.name }}
                    ({{ result.first_release_date.substring(0, 4) }})
                  </div>
                </button>
              </li>
            </ul>

            <div
              v-show="searchResults.next"
              class="horizontal-padding load-more-content"
            >
              <button
                v-show="moreSearchResultsStatus === 'idle' && searchResults.next"
                ref="loadMoreSearchResultsButton"
                type="button"
                class="btn btn-outline-primary load-more-search-results-button"
                @click="loadMoreSearchResults(true)"
              >
                Load More Search Results
              </button>

              <spinner
                v-if="moreSearchResultsStatus === 'loading'"
                preset="large"
              />

              <template v-else-if="moreSearchResultsStatus === 'error'">
                <alert variant="danger">
                  An error occurred while trying to load more search results.
                  Please check your connection and try again.
                </alert>

                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="loadMoreSearchResults(true)"
                >
                  Try Again
                </button>
              </template>
            </div>
          </div>

          <div
            v-else
            class="no-results text-center"
          >
            No results
          </div>
        </template>

        <div
          v-else
          class="horizontal-padding loading-or-error"
        >
          <spinner
            v-if="['idle', 'loading'].includes(searchResultsStatus)"
            preset="large"
          />

          <template v-else-if="searchResultsStatus === 'error'">
            <alert variant="danger">
              An error occurred while trying to load the search results. Please
              check your connection and try again.
            </alert>

            <button
              type="button"
              class="btn btn-outline-primary"
              @click="loadSearchResults(true)"
            >
              Try Again
            </button>
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="errorMessage !== ''"
      class="invalid-feedback js-form-error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { ApiError } from '@virgodev/bazaar/functions/api';
import debounce from 'debounce';
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick, PropType } from 'vue';
import { SearchIcon } from '@zhuowenli/vue-feather-icons';

export default defineComponent({
  components: {
    SearchIcon,
  },
  props: {
    selectedGame: {
      type: Object as PropType<{
        nameAndYear: string;
        slug: string;
        coverImageId: string;
        platforms: Array<string>;
      } | Record<string, never>>,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
  },
  emits: [
    'changed',
  ],
  data: () => ({
    abortController: null as null | AbortController,
    searchKeywords: '',
    searchResultsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    searchResults: {} as {
      next: string | null,
      previous: string | null,
      results: Array<Record<string, string>>,
    },
    moreSearchResultsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    changeGame() {
      this.$swal.fire({
        title: 'Are You Sure?',
        text: 'Do you really want to change the game?',
        showCancelButton: true,
        confirmButtonText: 'Yes, Change It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.searchKeywords = '';
          this.searchResultsStatus = 'idle';
          this.$emit('changed', {});

          nextTick(() => {
            (document.getElementById('game') as HTMLInputElement).focus();
          });
        }
      });
    },
    async loadSearchResults(includeDelay: boolean) {
      if (includeDelay) {
        // This delay is here for situations in which a click would undesirably
        // cause the search results to close.
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      const searchKeywords = this.searchKeywords.trim();

      if (searchKeywords === '') {
        this.searchResultsStatus = 'idle';
        this.searchResults = {
          next: null,
          previous: null,
          results: [],
        };
        return;
      }

      this.searchResultsStatus = 'loading';
      this.abortController = new AbortController();

      const responseData = await this.api({
        url: `games/search/?q=${searchKeywords}&include_platforms=true`,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        this.searchResults = responseData.body;
        this.searchResultsStatus = 'loaded';

        if (this.searchResults.next) {
          nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreSearchResults(false);
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreSearchResultsButton as HTMLButtonElement);
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.searchResultsStatus = 'error';
      }
    },
    async loadMoreSearchResults(includeDelay: boolean) {
      if (includeDelay) {
        // This delay is here for situations in which a click would undesirably
        // cause the search results to close.
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      this.moreSearchResultsStatus = 'loading';
      this.abortController = new AbortController();

      const responseData = await this.api({
        url: this.searchResults.next as string,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        // this.searchResults.count = responseData.body.count;
        this.searchResults.next = responseData.body.next;
        this.searchResults.previous = responseData.body.previous;
        this.searchResults.results = this.searchResults.results.concat(
          responseData.body.results,
        );
        this.moreSearchResultsStatus = 'idle';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.moreSearchResultsStatus = 'error';
      }
    },
    // eslint-disable-next-line func-names
    searchFieldInput: debounce(function () {
      // @ts-expect-error 'this' implicitly has type 'any' because it does not
      // have a type annotation.
      this.loadSearchResults(false);
    }, 300),
  },
});
</script>

<style lang="scss" scoped>
  .selected-game {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    word-wrap: break-word;
  }

  .search-results-container {
    position: relative;
  }

  .search-results {
    position: absolute;
    width: 100%;
    background-color: var(--gray-darker);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 3px 30px #000;
    overflow: hidden;
  }

  .search-results-list-and-load-more-content {
    max-height: 40vh;
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  .search-results-list {
    margin: 0;
    padding-left: 0;
    list-style: none;

    > li + li button {
      border-top: 1px solid var(--gray-darkest);
    }

    button {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: auto minmax(0, 1fr);
      align-items: center;
      padding: 10px 16px;
      width: 100%;
      text-align: left;
      transition: background-color 0.15s ease-in-out;
      word-wrap: break-word;

      @supports #{'\selector(*:focus-visible)'}  {
        &:focus {
          outline: 0;
        }

        &:focus-visible {
          background-color: var(--gray-dark);
        }
      }

      &:hover {
        background-color: var(--gray-dark);
      }

      img {
        width: 50px;
      }
    }
  }

  .load-more-content {
    margin: 1rem 0;
  }

  .load-more-search-results-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .no-results,
  .loading-or-error {
    margin: 1rem 0;
  }
</style>
