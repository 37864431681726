<template>
  <alert
    v-if="formErrors.non_field_errors !== undefined"
    variant="danger"
    class="js-form-error"
    dismissible
    @dismissed="formErrors = {}"
  >
    {{ formErrors.non_field_errors[0] }}
  </alert>

  <form @submit.prevent="submitForm">
    <div class="form-group">
      <label for="name">Team name</label>
      <input
        id="name"
        v-model="formFields.name"
        :class="{ 'is-invalid': formErrors.name !== undefined }"
        type="text"
        maxlength="75"
        required
      >
      <div
        v-if="formErrors.name !== undefined"
        class="invalid-feedback js-form-error"
      >
        {{ formErrors.name[0] }}
      </div>
    </div>

    <div class="form-group">
      <label for="slug">Web address</label>
      <input
        id="slug"
        v-model="formFields.slug"
        :class="{ 'is-invalid': formErrors.slug !== undefined }"
        type="text"
        maxlength="75"
        required
        aria-describedby="slug_help"
      >
      <small
        id="slug_help"
        class="form-text"
      >
        theplayersarena.com/team/{{ formFields.slug }}
      </small>
      <div
        v-if="formErrors.slug !== undefined"
        class="invalid-feedback js-form-error"
      >
        {{ formErrors.slug[0] }}
      </div>
    </div>

    <game-field
      :selected-game="selectedGame"
      :error-message="formErrors.game === undefined ? '' : formErrors.game[0]"
      @changed="selectedGameChanged"
    />

    <platforms-fieldset
      v-if="selectedGame.slug !== undefined && selectedGame.platforms.length > 1"
      :available-platforms="selectedGame.platforms"
      :initial-platforms="[]"
      :error-message="formErrors.platforms === undefined ? '' : formErrors.platforms[0]"
      @changed="selectedPlatformsChanged"
    />

    <div class="form-group">
      <fieldset :class="{ 'is-invalid': formErrors.visibility !== undefined }">
        <legend>Visibility</legend>

        <div
          v-if="formErrors.visibility !== undefined"
          class="invalid-feedback js-form-error"
        >
          Please choose an option.
        </div>

        <div class="form-check">
          <input
            id="visibility_public"
            v-model="formFields.visibility"
            type="radio"
            name="visibility"
            value="public"
            class="form-check-input"
            aria-describedby="visibility_public_description"
            required
          >
          <label
            for="visibility_public"
            class="form-check-label"
          >
            Public
          </label>

          <small id="visibility_public_description">
            Everybody can see this team's posts and comments
          </small>
        </div>

        <div class="form-check">
          <input
            id="visibility_listed"
            v-model="formFields.visibility"
            type="radio"
            name="visibility"
            value="listed"
            class="form-check-input"
            aria-describedby="visibility_listed_description"
          >
          <label
            for="visibility_listed"
            class="form-check-label"
          >
            Listed
          </label>

          <small id="visibility_listed_description">
            This team is discoverable (e.g. via search), but only members of
            this team can see its posts and comments
          </small>
        </div>

        <div class="form-check">
          <input
            id="visibility_unlisted"
            v-model="formFields.visibility"
            type="radio"
            name="visibility"
            value="unlisted"
            class="form-check-input"
            aria-describedby="visibility_unlisted_description"
          >
          <label
            for="visibility_unlisted"
            class="form-check-label"
          >
            Unlisted
          </label>

          <small id="visibility_unlisted_description">
            This team is not discoverable, members must be invited to join
            the team, and only members of this team can see its posts and
            comments
          </small>
        </div>
      </fieldset>
    </div>

    <transition-slide mode="out-in">
      <div
        v-if="formFields.visibility !== 'unlisted'"
        class="form-group"
      >
        <div class="form-check">
          <input
            id="invite_only"
            v-model="formFields.invite_only"
            type="checkbox"
            class="form-check-input"
          >
          <label
            for="invite_only"
            class="form-check-label"
          >
            This team should be invite only
          </label>
        </div>
      </div>
    </transition-slide>

    <div class="form-group">
      <label for="description">Description</label>
      <textarea
        id="description"
        v-model="formFields.description"
        :class="{ 'is-invalid': formErrors.description !== undefined }"
        required
      />
      <div
        v-if="formErrors.description !== undefined"
        class="invalid-feedback js-form-error"
      >
        {{ formErrors.description[0] }}
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-primary"
      :disabled="formSubmitting"
    >
      <template v-if="formSubmitting">
        Saving
        <spinner />
      </template>

      <template v-else>
        Save
      </template>
    </button>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import GameField from '@/components/games/GameField.vue';
import PlatformsFieldset from '@/components/games/PlatformsFieldset.vue';
import { GameInterface } from '@/interfaces/games';

export default defineComponent({
  components: {
    TransitionSlide,
    GameField,
    PlatformsFieldset,
  },
  data: () => ({
    formFields: {
      name: '',
      slug: '',
      visibility: '',
      invite_only: false,
      description: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    selectedGame: {} as {
      nameAndYear: string;
      slug: string;
      coverImageId: string;
      platforms: Array<string>;
    } | Record<string, never>,
    selectedPlatforms: [] as Array<string>,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    selectedGameChanged(newGame: GameInterface | Record<string, never>) {
      if (newGame.slug === undefined) {
        this.selectedGame = {};
      } else {
        this.selectedGame = {
          nameAndYear: `${newGame.name} (${newGame.first_release_date.substring(0, 4)})`,
          slug: newGame.slug,
          coverImageId: newGame.cover_image_id,
          platforms: newGame.platforms as Array<string>,
        };
      }

      this.selectedPlatforms = [];
    },
    selectedPlatformsChanged(newPlatforms: Array<string>) {
      this.selectedPlatforms = newPlatforms;
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const json = JSON.parse(JSON.stringify(this.formFields));

      json.game = this.selectedGame.slug === undefined ? '' : this.selectedGame.slug;

      if (this.selectedPlatforms.length) {
        json.platforms = JSON.parse(JSON.stringify(this.selectedPlatforms));
      }

      const responseData = await this.api({
        url: 'teams/',
        method: 'POST',
        json,
      });

      this.formSubmitting = false;

      if (responseData.status === 201) {
        this.$router.push({ name: 'team', params: { teamSlug: responseData.body.slug } });
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>
