
import { ApiError } from '@virgodev/bazaar/functions/api';
import debounce from 'debounce';
import { SweetAlertResult } from 'sweetalert2';
import { defineComponent, nextTick, PropType } from 'vue';
import { SearchIcon } from '@zhuowenli/vue-feather-icons';

export default defineComponent({
  components: {
    SearchIcon,
  },
  props: {
    selectedGame: {
      type: Object as PropType<{
        nameAndYear: string;
        slug: string;
        coverImageId: string;
        platforms: Array<string>;
      } | Record<string, never>>,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
  },
  emits: [
    'changed',
  ],
  data: () => ({
    abortController: null as null | AbortController,
    searchKeywords: '',
    searchResultsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    searchResults: {} as {
      next: string | null,
      previous: string | null,
      results: Array<Record<string, string>>,
    },
    moreSearchResultsStatus: 'idle' as 'idle' | 'loading' | 'loaded' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    changeGame() {
      this.$swal.fire({
        title: 'Are You Sure?',
        text: 'Do you really want to change the game?',
        showCancelButton: true,
        confirmButtonText: 'Yes, Change It',
      }).then(async (result: SweetAlertResult) => {
        if (result.isConfirmed) {
          this.searchKeywords = '';
          this.searchResultsStatus = 'idle';
          this.$emit('changed', {});

          nextTick(() => {
            (document.getElementById('game') as HTMLInputElement).focus();
          });
        }
      });
    },
    async loadSearchResults(includeDelay: boolean) {
      if (includeDelay) {
        // This delay is here for situations in which a click would undesirably
        // cause the search results to close.
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      const searchKeywords = this.searchKeywords.trim();

      if (searchKeywords === '') {
        this.searchResultsStatus = 'idle';
        this.searchResults = {
          next: null,
          previous: null,
          results: [],
        };
        return;
      }

      this.searchResultsStatus = 'loading';
      this.abortController = new AbortController();

      const responseData = await this.api({
        url: `games/search/?q=${searchKeywords}&include_platforms=true`,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        this.searchResults = responseData.body;
        this.searchResultsStatus = 'loaded';

        if (this.searchResults.next) {
          nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreSearchResults(false);
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreSearchResultsButton as HTMLButtonElement);
          });
        }
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.searchResultsStatus = 'error';
      }
    },
    async loadMoreSearchResults(includeDelay: boolean) {
      if (includeDelay) {
        // This delay is here for situations in which a click would undesirably
        // cause the search results to close.
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      this.moreSearchResultsStatus = 'loading';
      this.abortController = new AbortController();

      const responseData = await this.api({
        url: this.searchResults.next as string,
        options: {
          signal: this.abortController.signal,
        },
      });

      if (responseData.status === 200) {
        // this.searchResults.count = responseData.body.count;
        this.searchResults.next = responseData.body.next;
        this.searchResults.previous = responseData.body.previous;
        this.searchResults.results = this.searchResults.results.concat(
          responseData.body.results,
        );
        this.moreSearchResultsStatus = 'idle';
      } else if (
        !(
          Object.prototype.hasOwnProperty.call(responseData, 'error')
          && (responseData as ApiError).error.name === 'AbortError'
        )
      ) {
        this.moreSearchResultsStatus = 'error';
      }
    },
    // eslint-disable-next-line func-names
    searchFieldInput: debounce(function () {
      // @ts-expect-error 'this' implicitly has type 'any' because it does not
      // have a type annotation.
      this.loadSearchResults(false);
    }, 300),
  },
});
