
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    availablePlatforms: {
      type: Object as PropType<Array<string>>,
      required: true,
    },
    initialPlatforms: {
      type: Array,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
  },
  emits: [
    'changed',
  ],
  data: () => ({
    selectedPlatforms: [],
  }),
  created() {
    this.selectedPlatforms = JSON.parse(JSON.stringify(this.initialPlatforms));
  },
});
