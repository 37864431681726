
import { defineComponent } from 'vue';
import CreateTeam from '@/components/teams/CreateTeam.vue';
import TeamList from '@/components/teams/TeamList.vue';

export default defineComponent({
  components: {
    CreateTeam,
    TeamList,
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
});
