<template>
  <div class="form-group">
    <fieldset :class="{ 'is-invalid': errorMessage !== '' }">
      <legend>Platforms</legend>

      <div
        v-if="errorMessage !== ''"
        class="invalid-feedback js-form-error"
      >
        {{ errorMessage }}
      </div>

      <div
        v-for="(platform, index) of availablePlatforms"
        :key="`platform${index}`"
        class="form-check"
      >
        <input
          :id="`platform${index}`"
          v-model="selectedPlatforms"
          type="checkbox"
          :value="platform"
          class="form-check-input"
          @change="$emit('changed', selectedPlatforms)"
        >
        <label
          :for="`platform${index}`"
          class="form-check-label"
        >
          {{ platform }}
        </label>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    availablePlatforms: {
      type: Object as PropType<Array<string>>,
      required: true,
    },
    initialPlatforms: {
      type: Array,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
  },
  emits: [
    'changed',
  ],
  data: () => ({
    selectedPlatforms: [],
  }),
  created() {
    this.selectedPlatforms = JSON.parse(JSON.stringify(this.initialPlatforms));
  },
});
</script>
