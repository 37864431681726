
import { defineComponent } from 'vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import GameField from '@/components/games/GameField.vue';
import PlatformsFieldset from '@/components/games/PlatformsFieldset.vue';
import { GameInterface } from '@/interfaces/games';

export default defineComponent({
  components: {
    TransitionSlide,
    GameField,
    PlatformsFieldset,
  },
  data: () => ({
    formFields: {
      name: '',
      slug: '',
      visibility: '',
      invite_only: false,
      description: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    selectedGame: {} as {
      nameAndYear: string;
      slug: string;
      coverImageId: string;
      platforms: Array<string>;
    } | Record<string, never>,
    selectedPlatforms: [] as Array<string>,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    selectedGameChanged(newGame: GameInterface | Record<string, never>) {
      if (newGame.slug === undefined) {
        this.selectedGame = {};
      } else {
        this.selectedGame = {
          nameAndYear: `${newGame.name} (${newGame.first_release_date.substring(0, 4)})`,
          slug: newGame.slug,
          coverImageId: newGame.cover_image_id,
          platforms: newGame.platforms as Array<string>,
        };
      }

      this.selectedPlatforms = [];
    },
    selectedPlatformsChanged(newPlatforms: Array<string>) {
      this.selectedPlatforms = newPlatforms;
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const json = JSON.parse(JSON.stringify(this.formFields));

      json.game = this.selectedGame.slug === undefined ? '' : this.selectedGame.slug;

      if (this.selectedPlatforms.length) {
        json.platforms = JSON.parse(JSON.stringify(this.selectedPlatforms));
      }

      const responseData = await this.api({
        url: 'teams/',
        method: 'POST',
        json,
      });

      this.formSubmitting = false;

      if (responseData.status === 201) {
        this.$router.push({ name: 'team', params: { teamSlug: responseData.body.slug } });
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
